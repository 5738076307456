import { ResponseType } from '@reviews/interfaces';

export const DescriptionTicketTemplates: { [key: string]: string } = {
    [ResponseType.Response_needed]: `Hi (ASSIGNEE NAME),
     
We received a review and would like to get your feedback on a few specific concerns before we post a manager response.

Please address all of the following points:
-
-
-

Full review:
"(REVIEW)"

Thank you in advance for your hard work!

*****Please reassign to Lorajean Harryman so we can get a manager response posted within 3 days.******

Thank you!
    `,
    [ResponseType.Feedback_FYI]: `Hi (ASSIGNEE NAME), The guest that checked out on (DATE) mentioned privately in their review:

“(REVIEW)”

I just wanted to make sure you were aware of their suggestions or concerns. There is no need for follow-up or response. 

Thank you!    
    `,
};
